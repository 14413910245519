import React from 'react'
import { Container, Col, Card } from 'react-bootstrap'

export default function Home() {

  return (
    <Container>
      <div className="mb-4">
        <Container>
          <Container>
              <div className="col-md-12">
                <Card className="card flex-md-row mt-4 box-shadow h-md-250">
                  <Card.Body>
                    <Col><Card.Title>Advent of Code 2022</Card.Title></Col>
                    <Col><Card.Subtitle>Advent Calendar of Coding Puzzles</Card.Subtitle></Col>
                    <Col><Card.Text>Solved each puzzle first in Python. Then went back to solve them again in Go and Rust as a learning exercise. At a certain point (days 16, 19 and 22-25) it became less of a fun challenge and more of a chore. At this point I stopped solving new puzzles and started again at day 1 with the other languages.</Card.Text></Col>
                    <Col><Card.Link href='https://github.com/TayYates/AdventOfCode/tree/main/2022'>Link to GitHub Repo</Card.Link></Col>
                  </Card.Body>
                </Card>
              </div>
          </Container>
        </Container>
      </div>
    </Container>
  )
}
