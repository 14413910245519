import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

export default function Education() {
  return (
    <Container>
      <Container>
        <div className="mb-3">
          <h3>Education</h3>
        </div>
        <Row>
          <div className="col-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Col><Card.Title>Georgia Institute of Technology</Card.Title></Col>
                <Col><Card.Text>MS Analytics</Card.Text></Col>
                <Col><Card.Text>2026 (expected)</Card.Text></Col>
                <Col><Card.Text className="mb-3">3.75/4.00</Card.Text></Col>
                <Col><Card.Link href='https://pe.gatech.edu/degrees/analytics'>Curriculum</Card.Link></Col>
                <Col>
                  <Card.Text>
                    Computational Concentration.
                    Classwork focused on machine learning algorithms, 
                    database design, data visualization and HCI
                  </Card.Text>
                </Col>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Col><Card.Title>University of Tennessee</Card.Title></Col>
                <Col><Card.Text>BS Chemical Engineering</Card.Text></Col>
                <Col><Card.Text>2016</Card.Text></Col>
                <Col><Card.Text className="mb-3">3.15/4.00</Card.Text></Col>
                <Col><Card.Link href='https://cbe.utk.edu/undergraduate/biomolecular-concentration/'>Curriculum</Card.Link></Col>
                <Col>
                  <Card.Text>
                    Biomolecular Concentration. Classwork focused on drug discovery, 
                    gene therapy with mRNA vectors, reactor design and 
                    the industrialization of biological reactions
                  </Card.Text>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    </Container>
  )
}