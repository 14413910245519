import React from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Skills() {
  return (
    <Container>
      <Container>
        <div className="mb-3">
          <h3>Proficiencies</h3>
        </div>
        <Row>
          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>Python</Card.Title></Col>
                  <Col>
                    <Card.Text>Metadata Extraction Workflows</Card.Text>
                    <Card.Text>Kernel Event Tracing<br/>- Pyinotify / Threading / Concurrent</Card.Text>
                    <Card.Text>Data Visualizations <br/>- Numpy / Pandas / Matplotlib</Card.Text>
                    <Card.Text>Simple APIs with Django</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>AWS</Card.Title></Col>
                  <Col>
                    <Card.Text>Frontends and APIs <br/>- S3 / Lambda / Cloudfront</Card.Text>
                    <Card.Text>Cost Models and Interface Diagrams</Card.Text>
                    <Card.Text>Migrating on-prem archive to <Link to='https://github.com/nasa/cumulus'>Cumulus</Link> <br/>- Terraform / Step Functions / Kubernetes</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>SQL</Card.Title></Col>
                  <Col>
                    <Card.Text>MySQL<br/>- MariaDB Galera Cluster</Card.Text>
                    <Card.Text>Learning Postgres</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>Linux</Card.Title></Col>
                  <Col>
                    <Card.Text>Light SysAdmin work<br/>- CentOS / RHEL / Amazon</Card.Text>
                    <Card.Text>Comfortable using Bash / Awk / Grep</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>DevOps</Card.Title></Col>
                  <Col>
                    <Card.Text>Product Owner in SAFe Agile Scrum</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-6 mb-3">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row>
                  <Col className='col-4'><Card.Title>Javascript</Card.Title></Col>
                  <Col>
                    <Card.Text>Fullstack Web Development <br/>- React / Express / Node.js</Card.Text>
                    <Card.Text>Interactive Data Visualiations with D3</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    </Container>
  )
}
