import React from 'react'
import { Container, Row, Card } from 'react-bootstrap'

export default function Experience() {
  return (
    <Container>
      <Container>
        <div className="mb-3">
          <h3>Experience</h3>
        </div>
        <Row>
          <div className="Row-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row><Card.Title>NASA (Crustal Dynamics / SSAI)</Card.Title></Row>
                <Row><Card.Title>Senior Software Engineer</Card.Title></Row>
                <Row><Card.Text className="mb-1">Jan 2021 - Present</Card.Text></Row>
                <Row>
                  <Card.Text><br></br> 
                    Operations lead for DORIS and VLBI archives<br></br>
                    Development lead for ESDIS Metrics System and Central Metadata Repository<br></br>
                    Backup Sys Admin and DB Admin<br></br>
                    (2021 - Present)<br></br>

                    <br></br>

                    Vice Chair for Global Geodetic Observation System Data Information Systems<br></br>
                    Member of International DORIS Service Governing Board<br></br>
                    (2024 - Present)<br></br>

                    <br></br>

                    Product Owner for Cloud Migration Scrum Team<br></br>(2023 - 2024)<br></br>

                    <br></br>

                    Lead Architect and Developer for Middleware Project<br></br>(2023)<br></br>
                  </Card.Text>
                </Row>

              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="Row-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row><Card.Title>Continental</Card.Title></Row>
                <Row><Card.Title>Data / Uniformity Engineer</Card.Title></Row>
                <Row><Card.Text className="mb-1">Jun 2019 - Jan 2021</Card.Text></Row>
                <Row>
                  <Card.Text><br></br>
                    Wrote web applications to improve quality at a greenfield facility<br></br><br></br>
                    Directly managed 4 technicians, training them to make data driven decisions with a focus on quality<br></br><br></br>
                    Maintained data from the processing machines in datalakes using AWS Redshift, and provided 
                    environments for tire engineers to access the data<br></br><br></br>
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="Row-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row><Card.Title>Smokey Mountain Motors</Card.Title></Row>
                <Row><Card.Title>Data Analyst</Card.Title></Row>
                <Row><Card.Text className="mb-1">Jul 2018 - May 2019</Card.Text></Row>
                <Row>
                  <Card.Text><br></br>
                    Built dashboards with regression models to assist with A/B testing and demand forecasts<br></br><br></br>
                    Used by the sales team to increase their digital footprint in a cost effective manner<br></br><br></br>
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="Row-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row><Card.Title>Michelin</Card.Title></Row>
                <Row><Card.Title>Quality Engineer</Card.Title></Row>
                <Row><Card.Text className="mb-1">Jun 2016 - Jun 2018</Card.Text></Row>
                <Row>
                  <Card.Text><br></br>
                    Project work included reducing the contamination rate by 30%<br></br><br></br>
                    and introducing a new processing aid that reduced failure rates by 20%<br></br><br></br>
                    Adjusted parameters for each SKU to meet the specific needs of OEM customers<br></br><br></br>
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="Row-md-6">
            <Card className="card flex-md-row mb-3 box-shadow h-md-250">
              <Card.Body>
                <Row><Card.Title>Kimberly Clark</Card.Title></Row>
                <Row><Card.Title>Process Engineering Intern</Card.Title></Row>
                <Row><Card.Text className="mb-1">Jan 2015 - May 2015</Card.Text></Row>
                <Row>
                  <Card.Text><br></br>
                    Achieved successful startup of $25M capital project 
                    that utilized recycled fiber in lieu of virgin fiber<br></br><br></br>
                    Volunteered for night shift to help ensure smooth ramp up of new machines<br></br><br></br>
                    Collected and analyzed data to validate process limits, 
                    using these data to reduce process downtime by 10%<br></br><br></br>
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    </Container>
  )
}
