import React from 'react'
import { Container, Col } from 'react-bootstrap'

function Footer() {
    return (
        <Container>
            <Col className="text-center py-3">Copyright <i className="far fa-copyright"></i> Taylor A Yates</Col>
        </Container>
    )
}

export default Footer
