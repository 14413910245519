import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Header() {

    return (
        <header>
            <Navbar bg="light" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link as={Link} to="/portfolio"><i className="fas fa-book"></i> Portfolio</Nav.Link> */}
                        <Nav.Link as={Link} to="/resume"><i className="far fa-file"></i> Resume</Nav.Link>
                        <Nav.Link href="https://github.com/TayYates"><i className="fab fa-github"></i> Github</Nav.Link>
                    </Nav>
                    {/* <Nav className="ml-auto">
                        {
                            userInfo
                                ? <Nav.Link as={Link} to="/" onClick={logoutHandler}><i className="fas fa-sign-in-alt"></i> Logout {userInfo.username}</Nav.Link>
                                : <Nav.Link as={Link} to="/login"><i className="fas fa-sign-in-alt"></i> Login</Nav.Link>
                        }
                    </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
