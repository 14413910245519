import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from './screens/Home';
import Resume from './screens/Resume';

function App() {
  return (
    <Router>
      <Header />
      <main className="py-2">
        <Routes>
          <Route exact path = '/' element = {<Resume />} />
          <Route exact path = '/resume' element = {<Resume />} />
          <Route exact path = '/portfolio' element = {<Home />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
