import { Container } from 'react-bootstrap'
import Education from '../components/education'
import Publications from '../components/publications'
import Skills from '../components/skills'
import Experience from '../components/experience'

export default function Resume() {

    return (
        <Container>
            <div className="mb-4"></div>
            <Education />
            <Experience />
            <Publications />
            <Skills />
        </Container>
    )
}
