import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

export default function Publications() {
  return (
    <Container>
      <Container>
        <div className="mb-3">
          <h3>Presentations</h3>
        </div>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>CDDIS Data Ingest Updates and Future Developments</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2"><b>Taylor Yates</b></Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">13th IVS General Meeting & 25th Anniversary, Tsukuba, Japan, March 2024</Card.Text></Row>
                  {/* <Row><Card.Link href='https://cddis.nasa.gov/docs/2023/CDDIS-Recent-Developments-and-Updates.pdf'>Poster</Card.Link></Row> */}
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>The Crustal Dynamics Data Information System (CDDIS) – Updates and Future Developments</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2">J. Woo, <b>T. Yates</b>, B. P. Michael</Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">AGU Fall Meeting 2023, San Francisco, CA, USA, December 2023</Card.Text></Row>
                  <Row><Card.Link href='https://cddis.nasa.gov/docs/2023/CDDIS-Recent-Developments-and-Updates.pdf'>Poster</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>CDDIS FAIR Commitment and NASA's Year of Open Science</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2">J. Woo, N. Pollack, <b>T. Yates</b>, B. P. Michael</Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">28th IUGG General Assembly, Berlin, Germany, July 2023</Card.Text></Row>
                  <Row><Card.Link href='https://cddis.nasa.gov/docs/2023/CDDIS_FAIR_Commitment_And_NASAs_Year_of_Open_Science.pdf'>Poster</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>GNSS Real-Time Data and Product Streams at NASA's CDDIS: User Applications and Caster Performance Improvements</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2">S. M. Blevins, <b>T. A. Yates</b>, N. Pollack, J. Ash, J. H. Roark, B. P. Michael</Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">AGU Fall Meeting 2022, Chicago, Illinois, USA, December 2022</Card.Text></Row>
                  <Row><Card.Link href='https://cddis.nasa.gov/docs/2022/SMBlevins_AGU2022-final.pdf'>Poster</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>DORIS Data Ingest Improvements at NASA CDDIS</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2"><b>T. Yates</b>, J. Woo, S. Blevins, B. Michael</Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">2022 IDS Workshop, Venice, Italy, November 2022</Card.Text></Row>
                  <Row><Card.Link href='https://cddis.nasa.gov/docs/2022/IDS_2022CDDISPoster.pdf'>Poster</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>VLBI Data Ingest Improvements at NASA CDDIS</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2"><b>T. Yates</b>, J. Woo, N. Pollack, J. Ash, J. Roark, S. Blevins, B. Michael</Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">12th IVS General Meeting, Helsinki, Finland, March 2022</Card.Text></Row>
                  <Row><Card.Link href='https://cddis.nasa.gov/docs/2022/GM2022_CDDIS.pdf'>Poster</Card.Link></Row>
                  <Row><Card.Link href="https://ivscc.gsfc.nasa.gov/publications/gm2022/33_yates_etal.pdf">Paper Format</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <Card className="card flex-md-row box-shadow h-md-250">
              <Card.Body>
                <Col>
                  <Row><Card.Title>Error Tracking at the CDDIS and Communication with the User Community</Card.Title></Row>
                  <Row><Card.Subtitle className="mb-2">J. Woo, S. Blevins, B. Michael, R. Limbacher, <b>T. Yates</b></Card.Subtitle></Row>
                  <Row><Card.Text className="mb-2">AGU Fall Meeting 2021, New Orleans, Louisiana, USA, December 2021</Card.Text></Row>
                  <Row><Card.Link href='https://agu2021fallmeeting-agu.ipostersessions.com/Default.aspx?s=A2-51-EF-5A-1B-B2-2B-F4-A7-97-6F-91-28-B0-B9-CA'>Poster</Card.Link></Row>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
    </Container>
  )
}